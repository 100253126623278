<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h3 class="h3 mb-3">課程紀錄</h3>
				<nav style="--bs-breadcrumb-divider: '>'">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'Client' }">客戶管理</router-link>
						</li>
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'ClientDetail', params: { id: clientData.id } }">{{
								clientData.name
							}}</router-link>
						</li>
						<li class="breadcrumb-item active">課程紀錄</li>
					</ol>
				</nav>
			</div>

			<button class="btn btn-info flex-shrink-0" @click="edit.showModal()">新增課程紀錄</button>
		</div>

		<table class="table table-hover mb-5">
			<thead class="bg-light">
				<tr>
					<th>上課日期</th>
					<th>課程名稱</th>
					<th class="text-center">課程堂次</th>
					<th class="text-center">引導員</th>
					<th class="text-center" style="width: 50px"></th>
					<th class="text-center" style="width: 50px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in pageList" :key="item.id" @click="toReportPage(item.id)">
					<td>{{ formatDatetime(item.class_date) }}</td>
					<td>{{ item.lesson_name }}</td>
					<td class="text-center">{{ item.lesson_count }}</td>
					<td class="text-center">
						<div v-for="doctor in item.doctors" :key="doctor.id">{{ doctor.name }}</div>
					</td>
					<td class="text-center" @click.stop>
						<a class="link-primary" role="button" @click.stop="edit.showModal(item.id)">編輯</a>
					</td>
					<td class="text-center" @click.stop>
						<a v-if="item.role_id !== 1" class="link-danger" role="button" @click.stop="deleteData(item.id)"
							>刪除</a
						>
					</td>
				</tr>
			</tbody>
		</table>

		<Pagination
			v-model="pagination.currentPage"
			:records="pagination.records"
			:per-page="pagination.perPage"
			:options="pagination.options"
			@paginate="getPageList"
		/>

		<div ref="editModal$" class="modal fade" tabindex="-1" data-bs-backdrop="static">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ edit.data.id >= 0 ? "編輯" : "新增" }}上課記錄</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<Vueform
							ref="editForm$"
							:schema="editFormSchema"
							:display-errors="false"
							validate-on="step"
						></Vueform>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
						<button type="button" class="btn btn-primary" @click="edit.submit()">確定</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiClient, apiClientLesson, apiLesson } from "@/assets/js/api.js";
import { formatDatetime } from "@/assets/js/common.js";
import { useIndexDB } from "@/assets/js/indexDB.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";

export default {
	name: "ClientLesson",
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const now = new Date();

		let clientIDB;
		let clientLessonIDB;
		let doctorIDB;

		// 客戶資料
		const clientData = reactive({
			id: 0,
			name: "",
		});

		const dataList = ref([]); // 客戶資料
		const pageList = ref([]); // 客戶資料分頁顯示

		// 分頁
		const pagination = reactive({
			currentPage: 1,
			records: 0,
			perPage: 20,
			options: {
				theme: "bootstrap4",
				hideCount: true,
			},
		});

		const editModal$ = ref(null);
		const editForm$ = ref(null);
		const editFormSchema = ref({
			class_date: {
				label: "上課日期",
				type: "date",
				displayFormat: "YYYY-MM-DD",
				loadFormat: "YYYY-MM-DD",
				valueFormat: "YYYY-MM-DD",
				default: now,
				rules: ["required"],
			},
			lesson_id: {
				label: "課程名稱",
				type: "select",
				native: false,
				canClear: false,
				canDeselect: false,
				search: true,
				rules: ["required"],
				default: "",
				items: [],
			},
			lesson_count: {
				label: "課程堂次",
				type: "select",
				canClear: false,
				canDeselect: false,
				native: false,
				search: true,
				default: "",
				rules: ["required"],
				items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
			},
			doctors: {
				label: "引導員",
				type: "tags",
				rules: ["required"],
				search: true,
				closeOnSelect: false,
				items: {},
				default: [],
			},
		});
		const edit = reactive({
			modal: {},
			data: {
				id: -1, // -1: 新增模式 ; >= 0: 編輯模式
			},
			async showModal(id) {
				edit.data.id = -1;
				editForm$.value.reset();

				// edit
				if (id) {
					let detailData = await clientLessonIDB.get(id);
					edit.data.id = detailData.id;

					detailData.doctors = detailData.doctors.map((item) => item.id);

					editForm$.value.load(detailData);
				}

				setTimeout(() => {
					editForm$.value.resetValidators();
					edit.modal.show();
				}, 100);
			},
			submit() {
				const data = editForm$.value.data;
				editForm$.value.validate().then(() => {
					if (!editForm$.value.invalid) {
						setPageLoading(true);

						let apiOptions = {
							method: "",
							data: {
								...data,
								client_id: clientData.id,
							},
						};
						if (edit.data.id < 0) {
							//add
							apiOptions.method = "post";
						} else {
							//edit
							apiOptions.method = "put";
							apiOptions.id = edit.data.id;
						}

						apiClientLesson(apiOptions)
							.then((response) => {
								setPageLoading(false);
								if (response.data.status == "0") {
									Swal.fire({
										icon: "success",
										text: response.data.message,
									});
									getList();
									edit.modal.hide();
								} else {
									Swal.fire({
										icon: "warning",
										text: response.data.message,
									});
								}
							})
							.catch(() => {
								setPageLoading(false);
							});
					}
				});
			},
		});

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		//取得 課程名稱選單
		const getLessonList = () => {
			setPageLoading(true);

			apiLesson({
				method: "get",
			})
				.then((response) => {
					let availableList = response.data.data.filter((item) => item.status == 1);
					editFormSchema.value.lesson_id.items = availableList.map((item) => {
						return {
							value: item.id,
							label: item.lesson_name,
						};
					});
					setPageLoading(false);
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		//取得 課程紀錄 清單
		const getList = () => {
			setPageLoading(true);

			apiClientLesson({
				method: "get",
				data: {
					client_id: clientData.id,
				},
			})
				.then((response) => {
					if (response.data.status == "0") {
						let data = response.data.data;

						dataList.value = data;

						clientLessonIDB.clear();
						data.forEach((item) => {
							clientLessonIDB.set(item.id, item);
						});

						pagination.currentPage = 1;
						pagination.records = data.length;
						getPageList(1);

						setPageLoading(false);
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						}).then(() => {
							router.push({ name: "Client" });
						});
					}
				})
				.catch(() => {
					router.push({ name: "Client" });
				});
		};

		//取得 課程紀錄 分頁
		const getPageList = (currentPage) => {
			let startIndex = (currentPage - 1) * pagination.perPage;
			let endIndex = startIndex + pagination.perPage;

			pageList.value = dataList.value.slice(startIndex, endIndex);
		};

		//刪除 課程
		const deleteData = (id) => {
			Swal.fire({
				icon: "question",
				text: "確定要刪除嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					setPageLoading(true);

					apiClientLesson({
						method: "delete",
						id,
					})
						.then((response) => {
							setPageLoading(false);
							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch((error) => {
							setPageLoading(false);
						});
				}
			});
		};

		//前往 訪談報告頁面
		const toReportPage = (id) => {
			router.push({ name: "ClientReport", params: { clientID: clientData.id, lessonID: id } });
		};

		onMounted(async () => {
			clientData.id = route.params.id;

			clientIDB = await useIndexDB("clients", "client");
			doctorIDB = await useIndexDB("doctors", "doctor");
			clientLessonIDB = await useIndexDB("clientLessons", "clientLesson");

			edit.modal = new Modal(editModal$.value);
			editModal$.value.addEventListener("hidePrevented.bs.modal", (event) => {
				Swal.fire({
					icon: "info",
					text: "是否要關閉表單?",
					showCancelButton: true,
					focusConfirm: false,
					confirmButtonText: "是",
					cancelButtonText: "否",
				}).then((result) => {
					if (result.isConfirmed) {
						edit.modal.hide();
					}
				});
			});

			//取得 客戶資訊
			clientData.name = (await clientIDB.get(parseInt(clientData.id))).name;

			//取得 引導員選單
			editFormSchema.value.doctors.items = (await doctorIDB.getAll()).map((item) => {
				return {
					label: item.name,
					value: item.id,
				};
			});
			getLessonList();
			getList();
		});

		return {
			edit,
			editModal$,
			clientData,
			pageList,
			pagination,
			editForm$,
			editFormSchema,
			getList,
			getPageList,
			deleteData,
			toReportPage,
			formatDatetime,
		};
	},
	beforeRouteEnter(to, from, next) {
		if (!to.params.id) {
			next({ name: "Client" });
		} else {
			next();
		}
	},
};
</script>

<style lang="scss" scoped>
.table-hover {
	tbody tr {
		cursor: pointer;
	}
}
</style>
