const replaceAll = (str, find, replace) => {
	return str.split(find).join(replace);
};

export const textEllipsis = (text, maxLength) => {
	let result = text.substring(0, maxLength - 1);
	if (text.length > maxLength) {
		result += "...";
	}
	return result;
};

export const removeHTMLTags = (str) => {
	return str.replace(/<(.|\n)*?>/g, "");
};

export const formatLabel = (label) => {
	let temp = replaceAll(label, `[/color]`, `</span>`);

	const colorList = {
		red: "#ea868f",
		pink: "#f1aeb5",
		orange: "#fecba1",
		yellow: "#ffe69c",
		green: "#a6e9d5",
		blue: "#9eeaf9",
		gray: "#dee2e6",
	};

	for (let key in colorList) {
		temp = replaceAll(temp, `[color ${key}`, `<span style="background-color: ${colorList[key]}">`);
		temp = replaceAll(
			temp,
			`<span style="background-color: ${colorList[key]}">]`,
			`<span style="background-color: ${colorList[key]}">`
		);
	}
	temp = temp.split(`[tag `);
	for (let i = 1; i < temp.length; i++) {
		temp[i] = temp[i].replace(`]`, `">`);
	}

	temp = temp.join(`<span data-index="`);
	temp = replaceAll(temp, `[/tag]`, `</span>`);

	return temp;
};

export const invalidMessage = (rule) => {
	let message = "";
	if (rule.indexOf("min") >= 0) {
		let number = rule.split(":");
		if (number.length > 0) {
			message = `不能少於 ${number[1]} 個字符。`;
		}
	} else if (rule.indexOf("max") >= 0) {
		let number = rule.split(":");
		if (number.length > 0) {
			message = `不能多於 ${number[1]} 個字符。`;
		}
	} else if (rule.indexOf("required") >= 0) {
		message = "不能空白。";
	}

	return message;
};

export const formatDatetime = (date = "", format = "YYYY/MM/DD") => {
	/*
		format: YYYY/MM/DD hh:mm:ss
	*/

	if (!date || date == null || date.length == 0) {
		return date;
	}

	let newDate = new Date(date);
	let result = format;

	let YYYY = newDate.getFullYear();
	let MM = newDate.getMonth() + 1;
	let DD = newDate.getDate();

	let hh = newDate.getHours();
	let mm = newDate.getMinutes();
	let ss = newDate.getSeconds();

	if (MM < 10) {
		MM = "0" + MM;
	}

	if (DD < 10) {
		DD = "0" + DD;
	}

	if (hh < 10) {
		hh = "0" + hh;
	}

	if (mm < 10) {
		mm = "0" + mm;
	}

	if (ss < 10) {
		ss = "0" + ss;
	}

	result = result.replace("YYYY", YYYY);
	result = result.replace("MM", MM);
	result = result.replace("DD", DD);

	result = result.replace("hh", hh);
	result = result.replace("mm", mm);
	result = result.replace("ss", ss);

	return result;
};

export const chainAsync = (arrayFunctions) => {
	let currentFunctionIndex = 0;
	const lastFunction = arrayFunctions[arrayFunctions.length - 1];
	goToNextFunction();

	function goToNextFunction() {
		const currentFunction = arrayFunctions[currentFunctionIndex];
		if (currentFunction == lastFunction) {
			currentFunction();
		} else {
			currentFunction(goToNextFunction);
			currentFunctionIndex += 1;
		}
	}
};

export const jumpToAnchor = (targat) => {
	var url = location.href;
	location.href = targat;
	history.replaceState(null, null, url);
};
